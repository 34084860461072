import { useState } from "react"
import styled from "styled-components"

import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePostNoiseMonitoringPresets } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { TPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

type CreatePresetDialogProps = Pick<TMDialogProps, "onClose" | "open"> & {
  presets: TPreset[]
}

export function CreatePresetDialog({
  presets,
  open,
  onClose,
}: CreatePresetDialogProps) {
  if (!open || !presets[0]) {
    return null
  }

  return (
    <CreatePresetDialogOpen
      presets={presets}
      initialPreset={presets[0]}
      open={open}
      onClose={onClose}
    />
  )
}

function CreatePresetDialogOpen({
  presets,
  open,
  onClose,
  initialPreset,
}: CreatePresetDialogProps & { initialPreset: TPreset }) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { orgId } = useOrganization()

  const [selectedBasePreset, setSelectedBasePreset] =
    useState<TPreset>(initialPreset)
  const [presetName, setPresetName] = useState("")
  const postNoiseMonitoringPresets = usePostNoiseMonitoringPresets({ orgId })

  function handleCreatePreset() {
    if (!selectedBasePreset || !presetName) return

    postNoiseMonitoringPresets.mutate(
      {
        name: presetName,
        copy_from_profile_id: selectedBasePreset.id,
      },
      {
        onSuccess(data) {
          onClose()
          navigate(Routes.SettingsPresetsNoiseEdit.location(data.id))
        },
      }
    )
  }

  if (!open || !selectedBasePreset) {
    return null
  }

  return (
    <MDialog
      title={t(langKeys.presets_create_new_preset)}
      description={t(langKeys.presets_create_new_preset_description)}
      onConfirm={handleCreatePreset}
      confirmLabel={t(langKeys.presets_create_new_preset_action)}
      confirmButtonProps={{
        loading: postNoiseMonitoringPresets.isLoading,
        type: "submit",
        form: FORM_ID,
      }}
      onClose={onClose}
      open={open}
      hideClose
    >
      <PresetDialogContainer onSubmit={(e) => e.preventDefault()} id={FORM_ID}>
        <MSelect
          label={t(langKeys.presets_create_new_preset_based_on)}
          value={selectedBasePreset.id}
          options={presets.map((preset) => ({
            label: preset.name,
            value: preset.id,
          }))}
          onChange={(selectedValue: string): void => {
            setSelectedBasePreset(
              presets.find((preset) => preset.id === selectedValue)!
            )
          }}
        />

        <MTextField
          label={t(langKeys.presets_create_new_preset_name)}
          required
          value={presetName}
          onChange={(val) => setPresetName(val)}
        />
      </PresetDialogContainer>
    </MDialog>
  )
}

const PresetDialogContainer = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const FORM_ID = "create-monitoring-preset-form"
